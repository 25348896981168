import React from 'react';
import api from '../../../config/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import Loading from '../../../components/Loading/Loading';
import { Box } from '@mui/material';
import CompletedTaskCard from './CompletedTaskCard';
import { TASK_CARD_HEIGHT } from '../../../utils/constant';
import useInfiniteQueryReducedResult from '../../../utils/useInfiniteQueryReducedResult';

const CompletedTaskComponent = ({ setCompletedTaskDetails }) => {
  const getCompletedTasks = async ({ pageParam }) => {
    const { data } = await api.get(`/api/user/profile/proposal?filter=completed&page=${pageParam}&size=10`);
    setCompletedTaskDetails((prev) => ({ ...prev, height: data.data.totalCount * TASK_CARD_HEIGHT }));
    return data;
  };
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['completedTasksInfiniteScroll'],
    queryFn: getCompletedTasks,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages.length * 10;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });
  const completedTasks = useInfiniteQueryReducedResult(data);
  if (isLoading) return <Loading />;
  return (
    <Box width='100%'>
      {completedTasks
        ? completedTasks?.map((task, index) => (
            <CompletedTaskCard
              key={task?._id}
              data={task}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              isFetching={isFetching}
              hasNextPage={hasNextPage}
              index={index}
              totalCount={completedTasks?.length}
            />
          ))
        : null}
    </Box>
  );
};

export default CompletedTaskComponent;

import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getCountry = async ({ lat, lng }) => {
  const { data: location } = await api.get(`/api/public/location?latitude=${lat}6&longitude=${lng}`);
  const addressComponents = await location?.data?.results[0]?.address_components;
  const addressComponentsLength = await addressComponents?.length;
  const value = await addressComponents[addressComponentsLength - 2]?.long_name;
  const code = await addressComponents[addressComponentsLength - 2]?.short_name;
  return { value, code };
};

const useGetCountryFromLatLon = (coordinates) => {
  return useQuery({
    queryKey: ['getLocation'],
    queryFn: () => getCountry(coordinates),
    enabled: !!coordinates?.lat && !!coordinates?.lng,
  });
};

export default useGetCountryFromLatLon;

import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import NoVideoComponent from './NoVideoComponent';
import InfoIcon from '../../assets/icons/InfoIcon';
import WarningModal from '../../components/WarningModal/WarningModal';
import MyVideoLoading from '../../components/Loading/MyVideoLoading';
import VideoDescriptionTag from '../../components/VideoDescriptionTag/VideoDescriptionTag';
import useGetRecordings from '../../hooks/useGetRecordings';

dayjs.extend(duration);

const MyVideos = () => {
  const navigate = useNavigate();
  const [openInfoModal, setOpenInfoModal] = React.useState(false);
  const { recordings, isLoading, result, pending } = useGetRecordings();
  console.log(recordings);
  console.log(result);
  if (isLoading || pending) return <MyVideoLoading />;
  return (
    <>
      <Box sx={{ pb: 8, position: 'relative', minHeight: '100dvh' }}>
        <TopNavigation title={'My Videos'} />
        <TopNavigationSpace />
        <Stack spacing={2} mt={2.5}>
          {recordings?.data?.totalCount === 0 ? (
            <NoVideoComponent />
          ) : (
            <>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant={'h6_dark'}>Latest Videos ({recordings?.data?.result?.length})</Typography>
                <IconButton onClick={() => setOpenInfoModal(true)}>
                  <InfoIcon />
                </IconButton>
              </Stack>
              <Box mt={2} sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }} gap={1}>
                {result?.map(({ data }, index) => {
                  return (
                    <Stack
                      key={data?.data?.asset?.id}
                      spacing={0.5}
                      onClick={() =>
                        navigate(
                          ROUTES.RECORDING_PLAY.replace(
                            ':streamId',
                            recordings?.data.result[index]?.streamDetails?.liveStreamId,
                          ).replace(':taskId', recordings?.data.result[index]?._id),
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <BackgroundImage
                        img={data?.data?.asset?.[0]?.asset?.thumbnail_url}
                        width={190}
                        height={150}
                        borderRadius={10}
                        border={(theme) => `2px solid ${theme.palette.primary.main}`}
                      >
                        <VideoDescriptionTag
                          duration={data?.data?.asset?.duration}
                          viewers={recordings?.data?.result[index]?.viewers}
                        />
                      </BackgroundImage>
                      <Typography variant={'h6_bold'}>{recordings?.data?.result[index].title}</Typography>
                    </Stack>
                  );
                })}
              </Box>
            </>
          )}
        </Stack>
      </Box>
      <WarningModal
        open={openInfoModal}
        handleClose={() => setOpenInfoModal(false)}
        message='Last 10 stream recordings will be available!'
      />
    </>
  );
};

export default MyVideos;

import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: { main: '#fdcc0a' },
    success: { main: '#59D2B2' },
    error: { main: '#FF706C' },
    warning: { main: '#FF9A3E' },
    blur: { main: '#5F5D5D80' },
    dark: { main: '#364043', light: '#f6f6f6' },
    lightText: { main: '#111111CC', light: '#737373' },
    text: { dark: '#111111', main: '#111111CC', light: '#737373' },
    border: { main: '#0000001A' },
  },
  shape: { borderRadius: 8 },
  typography: {
    fontFamily: ['Urbanist', 'sans-serif'].join(','),
    h1: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '38.4px',
      letterSpacing: '0.17px',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '33.6px',
      letterSpacing: '0.17px',
    },
    h3_dark: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '28px',
      letterSpacing: '0.17px',
    },
    h3_bold: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: '0.17px',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '21.6px',
      letterSpacing: '0.17px',
    },
    h5_dark: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '21px',
      letterSpacing: '0.17px',
    },
    h5_bold: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '0.17px',
    },
    h5_semibold: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '21px',
      letterSpacing: '0.17px',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '0.17px',
    },
    h6_dark: {
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h6_bold: {
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h6_semibold: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h6: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h7_dark: {
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h7_bold: {
      fontSize: '11px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h7: {
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    caption_bold: {
      fontSize: '9px',
      fontWeight: 600,
      lineHeight: '14px',
      letterSpacing: '0.17px',
    },
    caption_semibold: {
      fontSize: '9px',
      fontWeight: 500,
      lineHeight: '14px',
      letterSpacing: '0.17px',
    },
    caption: {
      fontSize: '9px',
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.17px',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: 'large' },
          style: {
            padding: '12px 24px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            padding: '6px 12px',
            fontSize: '13px',
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: '#000',
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '0.17px',
          borderImageWidth: '2px',
          textTransform: 'none',
          boxShadow: 'none',
          padding: '8px 12px',
          borderRadius: 50,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '20px !important',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '24px',
          height: '24px',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '& .MuiBottomNavigationAction-label': {
            fontSize: '9px',
            fontWeight: 500,
            lineHeight: '14px',
            letterSpacing: '0.17px',
          },
          '& .MuiBottomNavigationAction-label.Mui-selected': {
            fontSize: '9px',
          },
        },
      },
    },
  },
});
export default theme;

import React from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './styles.css';
import { Box } from '@mui/material';

const PhoneInputComponent = ({ name, control, rules, setCountryData, countryData }) => {
  console.log(countryData);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const isIndia = countryData.name === 'India';
        const inputValue = isIndia ? value : value + ' Optional';
        const placeholderText = isIndia ? 'Enter phone number' : 'Optional';
        console.log('inputValue', inputValue);
        return (
          <>
            {/*{!isIndia && <Typography variant='h6'>Optional</Typography>}*/}
            <PhoneInput
              country='in'
              value={value}
              onChange={(phone, data) => {
                console.log(phone.slice(data.dialCode.length));
                onChange(phone);
                setCountryData(data);
              }}
              placeholder={placeholderText}
              enableSearch={true}
            />
            {error && (
              <Box
                sx={{
                  color: (theme) => theme.palette.error.main,
                  fontSize: 9,
                  marginLeft: '14px !important',
                  marginTop: '4px !important',
                }}
              >
                {error.message}
              </Box>
            )}
          </>
        );
      }}
    />
  );
};

export default PhoneInputComponent;

import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const CustomTextArea = ({ name, control, label, type = 'text', rules }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <TextField
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          multiline
          rows={4}
          autoComplete={'off'}
          sx={{
            mb: 1,
            '& textarea': {
              fontSize: '16px',
              fontWeight: 600,
            },
          }}
          InputProps={{
            style: {
              // color: "hsla(228, 9%, 11%, 0.4)",
              borderRadius: '8px',
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
          variant='outlined'
          placeholder={label}
          type={type}
        />
      )}
    />
  );
};
export default CustomTextArea;

import React from 'react';
import { Controller } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import AsyncSelect from 'react-select/async';

const CustomAsyncReactSelect = ({ control, options, name, label, rules, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value = defaultValue }, fieldState: { error } }) => (
        <Stack spacing={1}>
          <Typography variant='h6_semibold' sx={{ color: '#1A1B1F66' }}>
            {label}
          </Typography>
          <AsyncSelect
            cacheOptions
            // defaultOptions={value}
            onChange={onChange}
            loadOptions={options}
            defaultValue={defaultValue}
            // value={value}
          />
          {error && (
            <Typography variant='caption' color='error' sx={{ m: '4px 14px !important' }}>
              {error.message}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};

CustomAsyncReactSelect.propTypes = {};

export default CustomAsyncReactSelect;

import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';
import { InputAdornment, Stack, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import BrokenCalenderIcon from '../../assets/icons/BrokenCalenderIcon';
import TextField from '@mui/material/TextField';

const CustomDatePicker = ({ name, label, control, rules, defaultValue, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
        console.log(value);
        return (
          <Stack spacing={1}>
            <Typography variant='h6_semibold' sx={{ color: '#1A1B1F66' }}>
              {label}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                onChange={onChange}
                value={value}
                {...rest}
                slots={{
                  textField: (props) => (
                    <TextField
                      {...props}
                      helperText={error ? error.message : null}
                      size='small'
                      error={!!error}
                      fullWidth
                      sx={{
                        mb: 1,
                        '& input': {
                          fontSize: '16px',
                          fontWeight: 600,
                          padding: '14px 20px',
                        },
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '8px',
                          borderColor: 'rgba(0, 0, 0, 0.1)',
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <BrokenCalenderIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant='outlined'
                      placeholder={label}
                    />
                  ),
                }}
              />
            </LocalizationProvider>
          </Stack>
        );
      }}
    />
  );
};

export default CustomDatePicker;

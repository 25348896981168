import React from 'react';
import api from '../../config/api';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../components/Loading/Loading';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import ShowPasswordIcon from '../../assets/icons/ShowPasswordIcon';
import ClockIcon from '../../assets/icons/ClockIcon';
import ROUTES from '../../routes';

const getUserWithTasks = async (userId) => {
  const { data } = await api.get(`/api/message/user/${userId}?page=1&size=5`);
  return data;
};
const updateMessageNotificationStatus = async (taskId) => {
  try {
    await api.post(`/api/message/read/${taskId}`);
  } catch (e) {
    console.error(e);
  }
};

const UserMessagePage = () => {
  const { userId } = useParams();
  const {
    data: userWithTasks,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['getUserWithTasks', userId],
    queryFn: () => getUserWithTasks(userId),
  });
  const navigate = useNavigate();
  if (isLoading) return <Loading />;
  if (isError) return 'Error';
  const { user, taskMessage } = userWithTasks.data;
  return (
    <Box>
      <TopNavigation title={'Messages'} />
      <TopNavigationSpace />
      <Stack mt={2.5} gap={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            borderRadius: 1,
            p: '12px 20px',
            border: (theme) => `1px solid ${theme.palette.border.main}`,
            backgroundColor: (theme) => theme.palette.dark.light,
          }}
        >
          <CustomAvatar avatarUrl={user.profile.avatarUrl} name={user.name} />
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography variant='h5_semibold'>{user.name}</Typography>
            <Typography color='text.light' variant='h6_semibold'>
              {user.profile.streamCount} Videos Created
            </Typography>
          </Stack>
          <Button size='small' variant='contained'>
            Request
          </Button>
        </Box>
        {taskMessage.map((message) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              borderRadius: 1,
              p: '12px 20px',
              border: (theme) => `1px solid ${theme.palette.border.main}`,
            }}
          >
            <Stack spacing={1} sx={{ flex: 1 }}>
              <Typography variant='h5_semibold'>{message.task.title}</Typography>
              <Stack spacing={0.5} direction='row' alignItems='center'>
                <ClockIcon />
                <Typography color='text.light' variant='h6_semibold'>
                  {dayjs(message.task.createdAt).fromNow()}
                </Typography>
              </Stack>
            </Stack>
            <IconButton
              size='small'
              onClick={async () => {
                await updateMessageNotificationStatus(message.task._id);
                navigate(ROUTES.CHAT_USER.replace(':taskId', message.task._id));
              }}
            >
              <ShowPasswordIcon />
            </IconButton>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default UserMessagePage;

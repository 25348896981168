import { useEffect, useState } from 'react';

const useGeoLocation = ({ setIsAllowLocationModal }) => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: null, lng: null },
  });

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
  };

  useEffect(() => {
    const onError = (error) => {
      setIsAllowLocationModal(true);
      setLocation({
        loaded: true,
        error: {
          code: error.code,
          message: error.message,
        },
      });
      // if (error.code === error.PERMISSION_DENIED) {
      //   navigator.geolocation.getCurrentPosition(onSuccess, onError);
      // }
    };
    if (!('geolocation' in navigator)) {
      onError({
        code: 0,
        message: 'Geolocation not supported',
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, [setIsAllowLocationModal]);

  return location;
};

export default useGeoLocation;

import * as React from 'react';

function RightArrowIcon(props) {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.62 3.953L13.667 8 9.62 12.046M7.34 8h6.213M2.333 8h2.313'
        stroke={props.variant === 'dark' ? '#010101' : '#737373'}
        strokeWidth={0.875}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default RightArrowIcon;

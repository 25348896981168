import { signInWithPopup } from 'firebase/auth';
import { auth, googleAuthProvider } from '../config/firebase';
import api from '../config/api';
import generateUniqueUserName from './generateUniqueUserName';
import saveDataToDatabase from './saveDataToDatabase';
import { isNewUser } from '../store';
import ROUTES from '../routes';
import { toast } from 'react-toastify';

const handleGoogleAuth = async (setIsLoading, locationError, setIsAllowLocationModal, location, navigate) => {
  setIsLoading(true);
  try {
    const userCredential = await signInWithPopup(auth, googleAuthProvider);
    const token = await userCredential.user.getIdToken();
    localStorage.setItem('token', token);
    localStorage.setItem('isNewUser', 'false');
    const { data: userProfile } = await api.get('/api/user/profile');
    if (userProfile.status === false) {
      if (locationError) {
        setIsAllowLocationModal(true);
        setIsLoading(false);
        return;
      }
      const { email, displayName, uid } = userCredential.user;
      const userName = generateUniqueUserName(email);
      await saveDataToDatabase({
        email,
        name: displayName,
        uid,
        method: 'GOOGLE',
        userName,
        country: location,
      });
      isNewUser.value = true;
      navigate(ROUTES.REFERRAL_CODE_INPUT);
    } else {
      const intendedRoute = localStorage.getItem('intendedRoute');
      localStorage.removeItem('intendedRoute');
      intendedRoute ? navigate(intendedRoute) : navigate(ROUTES.WELCOME);
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    if (error?.code === 'auth/popup-closed-by-user') {
      toast.info('Popup closed by user');
      return;
    } else toast.error('Invalid email or password');
    console.log(error);
  }
};

export default handleGoogleAuth;

import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { useForm } from 'react-hook-form';
import CustomTextFeildWithLabel from '../../components/CustomTextFeildWithLabel/CustomTextFeildWithLabel';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import { useNavigate } from 'react-router-dom';
import useProfile from '../../hooks/useProfile';
import Loading from '../../components/Loading/Loading';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import api from '../../config/api';
import CustomAsyncReactSelect from '../../components/CustomAsyncReactSelect/CustomAsyncReactSelect';
import { toast } from 'react-toastify';
import CustomTextAreaWithLabel from '../../components/CustomTextAreaWithLabel/CustomTextAreaWithLabel';
import ROUTES from '../../routes';

dayjs.extend(customParseFormat);

const EditProfilePage = () => {
  const navigate = useNavigate();
  const { data: profile, isLoading } = useProfile();
  const { control, handleSubmit, reset } = useForm();
  useEffect(() => {
    reset({
      name: profile?.data?.name,
      dob: profile?.data?.profile?.dob ? dayjs(profile?.data?.profile?.dob) : null,
      address: profile?.data?.profile?.address,
      description: profile?.data?.profile?.description,
    });
  }, [
    profile?.data?.name,
    profile?.data?.profile?.dob,
    profile?.data?.profile?.address,
    reset,
    profile?.data?.profile?.description,
  ]);

  const submitPersonalDetails = async (value) => {
    try {
      const formData = new FormData();
      value?.address && formData.append('address', value.address.label ? value.address.label : value.address);
      value.dob && formData.append('dob', value?.dob);
      formData.append('name', value?.name);
      // value.tags && formData.append('tags', JSON.stringify(value.tags.map((t) => t.value)));
      formData.append('description', value?.description);
      await api.post('/api/user/profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Profile updated successfully');
      navigate(ROUTES.PROFILE);
      // window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };
  let timeoutId;
  const promiseOptions = async (inputValue) => {
    return new Promise((resolve) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(async () => {
        const { data } = await api.get(`/api/public/location?query=${inputValue || profile?.data?.profile?.address}`);
        const options = data?.data?.results?.map((item) => ({
          value: item?.formatted_address,
          label: item?.formatted_address,
        }));
        resolve(options);
      }, 300); // 300ms debounce time
    });
  };
  const defaultValue = {
    value: profile?.data?.profile?.address,
    label: profile?.data?.profile?.address,
  };
  if (isLoading) return <Loading />;
  return (
    <Box sx={{ pb: 8, height: '100%' }}>
      <TopNavigation title={'Profile'} />
      <TopNavigationSpace />
      <Box pt={2.5}>
        <Typography variant='h3_dark' mb={2}>
          Edit Profile
        </Typography>
        <form onSubmit={handleSubmit(submitPersonalDetails)}>
          <Stack spacing={2} pt={2}>
            <CustomTextFeildWithLabel
              name='name'
              label='Your Name'
              control={control}
              rules={{ required: 'Name is required' }}
              defaultValue={profile?.data?.profile?.dob || null}
            />
            <CustomDatePicker name='dob' label='Date of birth' control={control} maxDate={dayjs()} />
            <CustomAsyncReactSelect
              name='address'
              label='Address'
              control={control}
              options={promiseOptions}
              defaultValue={defaultValue}
            />
            <CustomTextAreaWithLabel name='description' label='BIO' control={control} />
          </Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pt: 2,
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button fullWidth variant='outlined' onClick={() => navigate(-1)} sx={{ color: '#000' }}>
              Cancel
            </Button>
            <Button fullWidth variant='contained' type='submit'>
              Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EditProfilePage;

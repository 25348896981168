import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import api from '../../config/api';
import Button from '@mui/material/Button';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { useForm } from 'react-hook-form';
import TaskDetails from '../../components/TaskDetails/TaskDetails';
import getDateByLocalTimezone from '../../utils/getDateByLocalTimezone';
import dayjs from 'dayjs';
import SendIcon from '../../assets/icons/SendIcon';
import ROUTES from '../../routes';
import MyVideoLoading from '../../components/Loading/MyVideoLoading';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import useGetTask from '../../hooks/useGetTask';

const UserChat = ({ message }) => {
  const { text, sender } = message;

  return (
    <Stack spacing={1}>
      <Box display={'flex'} gap={1} alignItems={'center'}>
        <CustomAvatar avatarUrl={sender.profile.avatarUrl} name={sender.name} />
        <Typography>{sender.name}</Typography>
      </Box>
      <Box
        sx={{
          p: '12px 20px',
          border: (theme) => `1px solid ${theme.palette.border.main}`,
          borderRadius: '12px',
        }}
      >
        <Typography>{text}</Typography>
      </Box>
    </Stack>
  );
};

const BottomDrawerBox = ({ icon, title, subtitle, onClick }) => {
  return (
    <Stack
      spacing={1.2}
      direction={'row'}
      sx={{
        p: '16px 20px',
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.border.main}`,
        cursor: 'pointer',
      }}
      alignItems={'center'}
      onClick={onClick}
    >
      {icon}
      <Stack>
        <Typography variant={'h5_dark'}>{title}</Typography>
        <Typography variant={'caption'} sx={{ color: '#111111B2' }}>
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

const getMessage = async (taskId) => {
  const { data } = await api.get(`/api/message/messages/${taskId}`);
  return data;
};

const ChatUserPage = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { taskId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const { data: proposal } = useGetProposal(proposalId);
  // const taskId = proposal?.data?.task;

  const { data: task, isLoading: isTaskLoading } = useGetTask(taskId);

  const {
    data: taskWithMessages,
    refetch: getMessages,
    isLoading: isTaskWithMessagesLoading,
  } = useQuery({
    queryKey: ['message', taskId],
    queryFn: () => getMessage(taskId),
  });

  const { control, handleSubmit, reset } = useForm({ defaultValues: { text: '' } });
  const sendMessage = async (values) => {
    try {
      await api.post(`/api/message`, { task: taskId, ...values });
      await getMessages();
      reset({ text: '' });
    } catch (error) {
      console.log(error);
    }
  };

  // const [bottomDrawerState, setBottomDrawerState] = React.useState(false);
  const createLiveStream = async (taskId) => {
    setIsLoading(true);
    try {
      const { data: streamDetails } = await api.post(`api/stream?task=${taskId}`);
      const streamId = await streamDetails?.data?.streamDetails?.liveStreamId;
      await api.put(`/api/stream/${streamId}/start`);
      setIsLoading(false);
      navigate(ROUTES.LIVE.replace(':taskId', taskId).replace(':streamId', streamId));
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  if (isLoading || isTaskWithMessagesLoading || isTaskLoading) return <MyVideoLoading />;
  const messages = taskWithMessages?.data?.messages || [];
  const formattedDate = getDateByLocalTimezone(task.data.requestAt, task.data.timeZone);
  return (
    <>
      <Stack sx={{ pb: 2, position: 'relative', maxHeight: '100dvh', minHeight: '100dvh' }}>
        <TopNavigation title={'My Task'} />
        <TopNavigationSpace />
        <Stack my={3} spacing={1.5}>
          <Typography variant={'h5_bold'}>{task.data.title}</Typography>
          <TaskDetails
            date={formattedDate}
            duration={task.data.duration}
            amount={task.data.amount}
            timeZone={task.data.timeZone}
          />
          {pathname.includes('my-task') ? (
            <Button
              variant='contained'
              sx={{ borderRadius: 50, p: '8px 12px' }}
              onClick={() => createLiveStream(taskId)}
              disabled={dayjs().toDate().toDateString() !== formattedDate.toDate().toDateString()}
            >
              Execute
            </Button>
          ) : (
            <Button
              variant='contained'
              sx={{ borderRadius: 50, p: '8px 12px' }}
              onClick={() => navigate(ROUTES.LIVE_PLAY.replace(':taskId', taskId))}
              disabled={dayjs().toDate().toDateString() !== formattedDate.toDate().toDateString()}
            >
              Join
            </Button>
          )}
        </Stack>
        <Stack pt={2.5} pb={16} spacing={4} sx={{ overflowY: 'auto' }}>
          {messages.map((message) => (
            <UserChat key={message?._id} message={message} />
          ))}
        </Stack>
        <Box sx={{ position: 'absolute', bottom: 70, width: '100%', zIndex: 3, backgroundColor: '#fff' }}>
          <form onSubmit={handleSubmit(sendMessage)}>
            <Stack spacing={1}>
              <CustomTextField
                label={'Message'}
                name={'text'}
                control={control}
                type={'text'}
                rules={{ required: 'Message is required' }}
                endAdornment={
                  <InputAdornment position={'end'}>
                    <IconButton type={'submit'}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }
                borderRadius='8px'
              />
            </Stack>
          </form>
        </Box>
      </Stack>
      {/*<BottomDrawer bottomDrawerState={bottomDrawerState} setBottomDrawerState={setBottomDrawerState}>*/}
      {/*  <Stack spacing={1.5}>*/}
      {/*    <BottomDrawerBox*/}
      {/*      icon={<GoLiveIcon />}*/}
      {/*      title={'Go Live'}*/}
      {/*      subtitle={'Go live right now'}*/}
      {/*      onClick={() => createLiveStream(taskId)}*/}
      {/*    />*/}
      {/*    <BottomDrawerBox*/}
      {/*      icon={<CalenderIcon />}*/}
      {/*      title={'Schedule a live'}*/}
      {/*      subtitle={'Schedule a live show for the future date'}*/}
      {/*      onClick={() => navigate(`/reschedule/${proposalId}/${taskId}`)}*/}
      {/*    />*/}
      {/*  </Stack>*/}
      {/*</BottomDrawer>*/}
    </>
  );
};

ChatUserPage.propTypes = {};

export default ChatUserPage;

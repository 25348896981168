import React from 'react';
import Stack from '@mui/material/Stack';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import Box from '@mui/material/Box';
import { Link, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import generateUniqueUserName from '../../utils/generateUniqueUserName';
import { userDetails } from '../../store';
import api from '../../config/api';
import { toast } from 'react-toastify';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import PhoneInputComponent from '../../components/PhoneInputComponent/PhoneInputComponent';
import ROUTES from '../../routes';

const getCountryList = async () => {
  const { data } = await api.get('/api/public/country');
  return data;
};
const CustomInput = ({ country, ...restProps }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '5px' }}>+{restProps.dialCode}</span>
      <input {...restProps} />
    </div>
  );
};

const RegisterForm = ({ setIsLoading }) => {
  const { handleSubmit, control, setError } = useForm();
  const navigate = useNavigate();
  const [terms] = useWatch({ control, name: ['terms'] });
  const [countryData, setCountryData] = React.useState({ name: 'India', countryCode: 'in', dialCode: '91' });

  const { data: countries } = useQuery({
    queryKey: ['getCountry'],
    queryFn: getCountryList,
  });

  const onSubmit = async (value) => {
    const { name, email, country, phone, confirmPassword, password } = value;
    console.log({
      ...value,
      method: 'MANUAL',
      country: { code: countryData?.countryCode, value: countryData?.name },
      phone: { code: countryData?.dialCode, value: phone.replace(countryData?.dialCode, '') },
    });

    setIsLoading(true);
    const { data: isEmailExist } = await api.get(`/api/public/validate?email=${email}`);
    if (isEmailExist?.data?.isEmail) {
      setIsLoading(false);
      toast.error('Email already exists');
      return;
    }
    if (confirmPassword !== password) {
      setError('confirmPassword', { message: 'Password does not match' });
    } else {
      const userName = generateUniqueUserName(email);
      userDetails.value = {
        ...value,
        method: 'MANUAL',
        userName,
        country: { code: countryData?.countryCode, value: countryData?.name },
        phone: { code: countryData?.dialCode, value: phone.replace(countryData?.dialCode, '') },
      };
      userDetails.value.phone.value === '' && delete userDetails.value.phone;
      try {
        await api.get(`/api/public/verification?email=${email}&name=${name}`);
        setIsLoading(false);
        navigate(ROUTES.EMAIL_VERIFICATION);
      } catch (e) {
        setIsLoading(false);
        toast.error('Email already exists');
        console.log(e);
      }
      console.log(userDetails.value);
    }
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <CustomTextField name={'name'} label={'Name'} control={control} rules={{ required: 'Name is required' }} />
          <PhoneInputComponent
            name={'phone'}
            control={control}
            rules={{ required: countryData.name === 'India' ? 'Phone is required' : false }}
            setCountryData={setCountryData}
            countryData={countryData}
          />
          {/*<Box display='flex' alignItems='center' gap={2}>*/}
          {/*  <CountryCodeSelect*/}
          {/*    name={'country'}*/}
          {/*    label={''}*/}
          {/*    control={control}*/}
          {/*    options={countries?.data || []}*/}
          {/*    closeMenuOnSelect={true}*/}
          {/*    // defaultValue='India'*/}
          {/*    rules={{ required: 'Country is required' }}*/}
          {/*    placeholder={'Country'}*/}
          {/*  />*/}
          {/*  <CustomNumberField*/}
          {/*    name={'phoneNumber'}*/}
          {/*    label={'Phone Number'}*/}
          {/*    control={control}*/}
          {/*    startAdornment={<InputAdornment position='start'>+91</InputAdornment>}*/}
          {/*    rules={{*/}
          {/*      required: 'Phone Number is required',*/}
          {/*      pattern: {*/}
          {/*        value: /^(\+\d{1,3}[- ]?)?\d{10}$/,*/}
          {/*        message: 'Invalid Phone Number',*/}
          {/*      },*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Box>*/}
          <CustomTextField
            name={'email'}
            label={'Email'}
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Invalid email format',
              },
            }}
            type={'email'}
          />
          <CustomTextField
            name={'password'}
            label={'Password'}
            control={control}
            rules={{ required: 'Password is required' }}
            type={'password'}
          />
          <CustomTextField
            name={'confirmPassword'}
            label={'Confirm Password'}
            control={control}
            rules={{ required: 'Password is required' }}
            type={'password'}
          />
        </Stack>
        <Stack spacing={3} mt={3}>
          <Stack spacing={1}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CustomCheckbox
                name={'terms'}
                control={control}
                rules={{ required: 'Please accept terms and conditions' }}
              />
              <Typography variant={'h5_semibold'}>
                I agree with bunpun's{' '}
                <Link
                  href='https://www.bunpun.com/terms-of-service/'
                  target='_blank'
                  variant={'h5_dark'}
                  sx={{ textDecoration: 'underline', color: '#000' }}
                >
                  Terms of Service
                </Link>{' '}
                &{' '}
                <Link
                  href='https://www.bunpun.com/privacy-policy/'
                  target='_blank'
                  style={{
                    textDecoration: 'underline',
                  }}
                  variant={'h5_dark'}
                  sx={{ textDecoration: 'underline', color: '#000' }}
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>{' '}
          </Stack>

          <Button variant='contained' type={'submit'} size='large' disabled={!terms}>
            Next
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default RegisterForm;
